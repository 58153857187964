<template>
  <div class="app-config">
    <p class="title">公告管理</p>
    <!-- 操作 -->
    <div class="action">
      <a-button type="primary" size="small" @click="showAddEditModal">新增公告 </a-button>
    </div>
    <!-- 表格 -->
    <a-table
      :dataSource="noticeDataSource"
      :columns="noticeColumns"
      :pagination="{
        pageSize: noticePageSize,
        current: noticeCurPage,
        total: noticeTotal
      }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #noticeIdx="row">
        {{ (noticeCurPage - 1) * noticePageSize + row.index + 1 }}
      </template>
      <template #display="row">
        {{ row.record.display ? '是' : '否' }}
      </template>
      <template #noticeAction="row">
        <div class="action">
          <span class="edit" @click="showAddEditModal(row)">编辑</span>
          <span class="del" @click="del(row)">删除</span>
        </div>
      </template>
    </a-table>
  </div>
  <add-edit-modal ref="AddEditModalRef" @addOrUpdateSuccess="search"></add-edit-modal>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, createVNode, onMounted } from 'vue';
import AddEditModal from './AddEditModal';
import { Modal, message } from 'ant-design-vue';
import api from '@/services';

const noticeColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'noticeIdx'
    }
  },
  {
    title: '公告标题',
    dataIndex: 'noticeTitle',
    align: 'center',
    ellipsis: true
  },
  {
    title: '公告内容',
    dataIndex: 'noticeContent',
    align: 'center',
    ellipsis: true
  },
  {
    title: '是否展示',
    dataIndex: 'display',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'display'
    }
  },
  {
    title: '展示截止日',
    dataIndex: 'deadLine',
    align: 'center',
    ellipsis: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'noticeAction'
    }
  }
];

export default defineComponent({
  components: {
    AddEditModal
  },
  setup() {
    const activeKey = ref('b');
    const isEdit = ref(false);

    const AddEditModalRef = ref();

    // notice table 相关变量
    const noticeTableState = reactive({
      noticePageSize: 5,
      noticeCurPage: 1,
      noticeTotal: 0,
      noticeDataSource: []
    });

    onMounted(() => {
      getNoticeList();
    });

    // 显示 新增 / 编辑 弹窗
    const showAddEditModal = row => {
      let rowData;
      if (row.record) {
        isEdit.value = true;
        rowData = row.record;
      } else {
        isEdit.value = false;
      }
      AddEditModalRef.value.showModal(isEdit.value, rowData);
    };

    // 获取公告设置 table data
    const getNoticeList = async () => {
      let params = {
        curPage: noticeTableState.noticeCurPage,
        pageSize: noticeTableState.noticePageSize
      };
      const { data, success } = await api.getNoticeList(params);
      if (success) {
        noticeTableState.noticeDataSource = data.records;
        noticeTableState.noticeTotal = data.total;
      }
    };

    const search = () => {
      noticeTableState.noticeCurPage = 1;
      getNoticeList();
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要删除该公告吗？`
        ),

        async onOk() {
          let { success } = await api.delAppNoticeById(row.record.id);
          if (success) {
            message.success('删除成功！');
            noticeTableState.noticeCurPage = 1;
            getNoticeList();
          }
        }
      });
    };

    const handlePageChange = page => {
      noticeTableState.noticeCurPage = page.current;
      getNoticeList();
    };

    return {
      search,
      del,
      noticeColumns,
      activeKey,
      AddEditModalRef,
      showAddEditModal,
      handlePageChange,
      getNoticeList,
      ...toRefs(noticeTableState)
    };
  }
});
</script>

<style lang="less" scoped>
.app-config {
  padding: 15px 20px;
  .title {
    margin-bottom: 20px;
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .status-tag,
      .action > span {
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }
      .status-tag {
        background-color: #4acc90;
        color: #fff;
      }

      .action {
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
          margin-right: 14px;
        }
        .del {
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
