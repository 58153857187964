<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      centered
    >
      <p class="title">{{ isEdit ? '编辑' : '新增' }}公告</p>
      <a-divider />
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确定</a-button>
      </template>
      <!-- APP公告设置 -->
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="公告标题">
          <a-input v-model:value.trim="noticeTitle" placeholder="请输入公告标题" allowClear></a-input>
        </a-form-item>
        <a-form-item label="公告内容">
          <a-input v-model:value.trim="noticeContent" placeholder="请输入公告内容" allowClear></a-input>
        </a-form-item>
        <a-form-item label="是否展示">
          <a-switch v-model:checked="display"></a-switch>
        </a-form-item>
        <a-form-item label="截止日期">
          <a-date-picker v-model:value="deadLine" allowClear></a-date-picker>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, onMounted, nextTick } from 'vue';
import api from '@/services';
import moment from 'moment';
import { message } from 'ant-design-vue';

export default defineComponent({
  setup(props, context) {
    const loading = ref(false);
    const visible = ref(false);

    const isEdit = ref(false);
    const ModalBoxRef = ref();

    const labelCol = reactive({ span: 6, offset: 3 });
    const wrapperCol = reactive({ span: 14 });
    const noticeFormState = reactive({
      id: '',
      noticeTitle: '',
      noticeContent: '',
      display: false,
      deadLine: ''
    });

    onMounted(() => {});

    const showModal = (flag, rowData) => {
      visible.value = true;
      nextTick(() => {
        isEdit.value = flag;
        if (flag) {
          Object.keys(noticeFormState).map(key => {
            noticeFormState[key] = rowData[key];
          });
          noticeFormState.deadLine = moment(rowData.deadLine);
        }
      });
    };

    const handleOk = async () => {
      loading.value = true;
      Object.keys(noticeFormState).map(key => {
        if(key == 'id' || key == 'display') {

        }
        else if(!noticeFormState[key]) {
          loading.value = false
        }
      });
      if(!loading.value) {
        message.error('请输入完整')
        return false 
      }
      let functionName = isEdit.value ? 'updateAppNotice' : 'addAppNotice';
      let params = {
        ...noticeFormState,
        deadLine: moment(noticeFormState.deadLine).format('YYYY-MM-DD')
      };
      console.log('noticeFormState===', noticeFormState);
      const { success } = await api[functionName](params);
      if (success) {
        message.success(`${isEdit.value ? '编辑' : '新增'}成功！`);
        context.emit('addOrUpdateSuccess');
      }
      loading.value = false;
      visible.value = false;
    };

    const handleCancel = () => {
      visible.value = false;
      clearForm();
    };

    const clearForm = () => {
      Object.keys(noticeFormState).map(key => {
        noticeFormState[key] = '';
      });
      noticeFormState.display = false;
    };

    return {
      isEdit,
      labelCol,
      wrapperCol,
      ...toRefs(noticeFormState),
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
      ModalBoxRef,
      clearForm
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .ant-form {
    .ant-form-item {
      .ant-upload-drag-icon {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.anticon-plus) {
          color: #d4d4d4;
          font-size: 24px;
        }
      }
      .cover-img {
        height: 148px;
        border-radius: 10px;
      }
    }
  }
}
</style>
